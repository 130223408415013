<template>
  <div class="about">
    <v-card color="#121212" class="make-wish" contain>
      <v-img :lazy-src="require('@/assets/images/Makeawish_logo.png')"
        :src="require('@/assets/images/Makeawish_logo.png')"></v-img>
    </v-card>
    <v-container fluid>
      <v-row>
        <v-col class="center">
          <v-btn :href="settings.donation_link" target="_blank" color="#fbb040">Donate to Make-A-Wish Wyoming</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card color="#fff">
      <v-card-title color="#000">Our Sponsors</v-card-title>
      <v-container fluid>
        <v-row>
          <v-col v-for="sponsor in sponsors" :key="sponsor.id" class="sponsor">
            <v-img :lazy-src="sponsor.logoURL" :src="sponsor.logoURL"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div style="text-align:center;margin-top:200px;margin-bottom:200px;">
      <v-btn @click="clearData" color="#fbb040">Clear My Data</v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Sponsor',
  data () {
    return {};
  },
  created () {
  },
  mounted () {
    this.$store.dispatch('sponsor/getSponsors')
  },
  watch: {
    $route (to, from) {
      console.log(to);
      console.log(from);
      // react to route changes...
      this.loadData();
    },
  },
  methods: {
    clearData: function () {
      localStorage.removeItem('brews');
      window.location.href = window.location.origin
    },
  },
  computed: {
    ...mapGetters('sponsor', ['sponsors']),
    ...mapGetters('setting', ['settings']),
  },
};
</script>
<style scoped>
.center {
  text-align: center;
}

.make-wish {
  padding: 1rem;
}

.sponsor .v-image {
  max-width: 80%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.v-card {
  padding-top: 1rem;
}

.v-card__title {
  font-family: "Germania One", cursive;
  font-size: 3rem;
  text-align: center;
  color: #121212;
  display: block;
  margin: 0 auto;
}

a.v-btn {
  color: #121212;
}
</style>